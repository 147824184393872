import React, {Component} from 'react';
import intro from '../assets/images/botondlogo.jpg';
import mobilepic from '../assets/images/botondlogo.jpg';

import {useMediaQuery} from 'react-responsive';

const Desktop = ({children}) => {
    const isDesktop = useMediaQuery({minWidth: 992})
    return isDesktop ? children : null
}
const Tablet = ({children}) => {
    const isTablet = useMediaQuery({minWidth: 768, maxWidth: 991})
    return isTablet ? children : null
}
const Mobile = ({children}) => {
    const isMobile = useMediaQuery({maxWidth: 767})
    return isMobile ? children : null
}
const Default = ({children}) => {
    const isNotMobile = useMediaQuery({minWidth: 768})
    return isNotMobile ? children : null
}

export default class Slider extends Component {
    render() {
        const {lang} = this.props.match.params;
        return (
            <div id="carousel" className="carousel slide mb-4" data-ride="carousel">
                <div className="carousel-inner">
                    <Mobile>
                        <div className="carousel-item active" data-interval="2000" style={{
                            background: `url(${mobilepic}) no-repeat center center scroll`,
                            'backgroundSize': `contain`
                        }}/>
                    </Mobile>
                    <Default>
                        <div className="carousel-item active" style={{
                            background: `url(${intro}) no-repeat center center scroll`,
                            'backgroundSize': `contain`
                        }}/>
                    </Default>

                </div>
                <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>);
    }
}
