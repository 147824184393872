import React, {Component} from 'react'


export default class Footer extends Component {
    render() {
        const {config} = this.props;
        const {lang} = this.props.match.params;
        return (
            <footer>
                <div className="container text-center">
                    <div id="footer_content">
                        <i className="fa fa-copyright fa-lg"></i> <span onDoubleClick={() => alert(config?.paylikeApiKey)}>2022. Botond Fitness</span>
                    </div>
                </div>
            </footer>
        );
    }
}
