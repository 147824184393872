import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom'
import {Trans, withTranslation} from 'react-i18next';

import Header from './Header';
import Footer from './Footer';
import Slider from "./Slider";
import Section from "./Section";
import CookieConsent from "react-cookie-consent";
import erfaPic from "../assets/images/infoblokk.png";

class App extends Component {
    state = {
        config: {}
    }

    async componentDidMount() {
        fetch(`/config.json?v=${Date.now()}`)
            .then((r) => r.json())
            .then((data) => {
                this.setState({config: data});
            });

        if (this.props.location?.hash) {
            window.history.scrollRestoration = 'manual';
            var props = this.props;
            window.onload = function () {
                var element = document.getElementById(props.location?.hash.substr(1));
                element.scrollIntoViewIfNeeded();
            }
        }
    }

    render() {
        const {lang} = this.props.match.params;
        const {config} = this.state;
        return (
            <>
                <Header {...this.props} lang={"hu"}/>
                <Switch>
                    <Route path="*" render={(props) =>

                        <>
                            <div id="alert-container"></div>

                            <Slider {...this.props} />

                            <Section id={`openinghours`} title={<Trans i18nKey="DICT.openinghours"/>} {...this.props}>
                                <div className="row">
                                    {Object.keys(config?.openinghours || {}).map(gym =>
                                        <div key={gym} className="col-12 col-md-6">
                                            <div className="box">
                                                <span className="boxtitle">&raquo; {gym}</span><br/>
                                                {Object.keys(config?.openinghours[gym] || {}).map(day =>
                                                    <><span className="tantext"><Trans
                                                        i18nKey={`DICT.${day}`}/></span> {config.openinghours[gym][day]}<br/></>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <hr/>

                            </Section>

                            <Section id={`prices`} title={<Trans i18nKey="DICT.prices"/>} {...this.props}>
                                <div className="row">
                                    {Object.keys(config?.prices || {}).map(gym =>
                                        <div key={gym} className="col-12 col-md-6">
                                            <div className="box">
                                                <span className="boxtitle">&diams;  {gym}</span><br/>
                                                <div className="row font-weight-bold">
                                                    <div className="col-6">&nbsp;</div>
                                                    <div className="col-3"><Trans i18nKey="DICT.studentprice"/>*</div>
                                                    <div className="col-3"><Trans i18nKey="DICT.normalprice"/></div>
                                                </div>
                                                {Object.keys(config?.prices[gym] || {}).map(row =>
                                                    <div className="row">
                                                        <div className="col-6"><Trans i18nKey={`DICT.${row}`}/></div>
                                                        <div className="col-3">{config.prices[gym][row][0]}</div>
                                                        <div className="col-3">{config.prices[gym][row][1]}</div>
                                                    </div>
                                                )}
                                                <div className="row font-italic">
                                                    <div className="col-12">*<Trans i18nKey="DICT.studentpricerule"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Section>

                            <Section id={`contact`} title={<Trans i18nKey="DICT.contact"/>} {...this.props}>
                                <div className="row">
                                    {Object.keys(config?.contact || {}).map(gym =>
                                        <div key={gym} className="col-12 col-md-6">
                                            <div className="box">
                                                <span className="boxtitle">&raquo; {gym}</span><br/>
                                                {Object.keys(config?.contact[gym] || {}).map(row =>
                                                    <><span className="tantext"><Trans
                                                        i18nKey={`DICT.${row}`}/></span>: {config.contact[gym][row]}<br/></>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Section>
                            <Section id={`fb`} title={<Trans i18nKey="DICT.facebook"/>} {...this.props}>
                                <div className="row">
                                    <div className="col-12 col-md-6">

                                        <div className="facebook_news">
                                            <div className="fb-like-box"
                                                 data-href="http://www.facebook.com/pages/Botond-Fitness/140973272653961?ref=ts&fref=ts"
                                                 data-width="450" data-height="700" data-show-faces="true"
                                                 data-stream="true" data-header="false"
                                                 show-border="false"></div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">

                                        <div className="facebook_news">
                                            <div className="fb-like-box"
                                                 data-href="https://www.facebook.com/pages/Cutler-Gym-M%C3%B3v%C3%A1r/1500558630180449"
                                                 data-width="450" data-height="700" data-show-faces="true"
                                                 data-header="false" data-stream="true"
                                                 data-show-border="false">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer"></div>
                                </div>
                            </Section>
                            <Section id={`erfa`} title={<Trans i18nKey="DICT.erfa"/>} {...this.props}>
                                <img src={erfaPic} alt="erfa"/>
                                <div className="row">
                                    <div className="col-6 font-weight-bold">A kedvezményezett neve:</div>
                                    <div className="col-6">Freimann Kereskedelmi és Szolgáltató Kft</div>
                                    <div className="col-6 font-weight-bold">A projekt címe:</div>
                                    <div className="col-6">9200 Mosonmagyaróvár, Szent István király u. 17. és 9200
                                        Mosonmagyaróvár, Kálnoki u. 11.
                                    </div>
                                    <div className="col-6 font-weight-bold">A projekt tartalmának rövid bemutatása:</div>
                                    <div className="col-6">A Cutler Gym Móvár és Botond Fitness edzőtermek forgóeszköz
                                        finanszírozása
                                    </div>
                                    <div className="col-6 font-weight-bold">A szerződött támogatott hitel összege:</div>
                                    <div className="col-6">10.000.000,- Ft, azaz Tízmillió forint.</div>
                                    <div className="col-6 font-weight-bold">A felhívás azonosító jele:</div>
                                    <div className="col-6">GINOP-9.1.1-21</div>
                                    <div className="col-6 font-weight-bold">A projekt tervezett befejezési dátuma (ha megvalósult, akkor
                                        a tényleges befejezés):
                                    </div>
                                    <div className="col-6">2021.12.31.</div>
                                </div>
                            </Section>

                        </>
                    }/>
                </Switch>
                <Footer {...this.props} config={config}/>
                <CookieConsent
                    buttonText={<Trans i18nKey="DICT.approve"/>}
                    style={{background: "#2B373B"}}
                    buttonStyle={{color: "#4e503b", fontSize: "13px"}}
                >
                    <Trans i18nKey="DICT.page_use_cookies"/>
                </CookieConsent>
            </>
        );
    }
}

export default withTranslation()(App);
