import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import translationHU from './locales/hu/translation.json';
import translationEN from './locales/en/translation.json';
// the translations
const resources = {
    hu: {
        translation: translationHU,
    },
    en: {
        translation: translationEN,
    },
};

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en', // use en if detected lng is not available
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        returnEmptyString: false,
        // react-i18next options
        // react: {
        //
        // }
    });

export default i18n;
