import React from 'react'
import { createRoot } from 'react-dom/client';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
import App from './components/App';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.scss';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <div>
                    <Switch>
                        <Route path="/" component={App}></Route>
                    </Switch>
                </div>
            </BrowserRouter>
        </I18nextProvider>
    </React.StrictMode>)
