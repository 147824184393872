import React, {Component} from 'react';
import {withRouter, NavLink} from 'react-router-dom';

export default class Section extends Component {
    render() {
        const {lang} = this.props.match.params;
        const {title, id} = this.props;
        return (
            <section id={id} className="container content-section">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h2>{title}</h2>
                        {this.props.children}
                    </div>
                </div>
            </section>
        );
    }
}
