import React, {Component} from 'react';
import {withRouter, NavLink} from 'react-router-dom';
import {Trans, getI18n} from 'react-i18next';
import FlagEn from '../assets/images/en_flag.jpg';
import FlagHu from '../assets/images/hu_flag.jpg';
import classNames from "classnames";

export default class Header extends Component {
    state = {
        navbar: {
            show: false
        },
        // lang: localStorage.getItem('i18nextLng')
    }

    componentDidMount() {
        const i18n = getI18n();
        this.setState({lang:i18n.language})
    }

    toggleCollapse = () => {
        this.setState({navbar: {show: !this.state.navbar.show}});
    }

    changeLanguage = (lng) => {
        const i18n = getI18n();
        i18n.changeLanguage(lng, this.setState({lang: lng}));
        //this.toggleCollapse();
    }

    render() {
        const {navbar, lang} = this.state;
        const {show} = navbar;

        return (
            <nav className="navbar navbar-expand-xl fixed-top navbar-custom navbar-light">
                <div className="container">
                    <button className="navbar-toggler collapsed" type="button" aria-controls="navbar"
                            aria-expanded="false"
                            aria-label="Toggle navigation" onClick={(e) => this.toggleCollapse()}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <a className="navbar-brand" href="/">Botond Fitness</a>
                    <div className="navbar-text text-right">
                        <div className="btn-group dropleft" id="lang-select">
                            <a href="" className="dropdown-toggle" data-toggle="dropdown"
                               aria-haspopup="true" aria-expanded="false"><img id="lang-flag"
                                                                               src={lang && lang.toLocaleLowerCase().includes('hu') ? FlagHu : FlagEn}
                                                                               height="25" width="25"
                                                                               alt=""/>
                            </a>
                            <ul className="dropdown-menu">
                                <li className="nav-item dropdown-item" onClick={() => this.changeLanguage('hu')}><img
                                    src={FlagHu}
                                    height="20" alt="hu"/> Magyar
                                </li>
                                <li className="nav-item dropdown-item" onClick={() => this.changeLanguage('en')}><img
                                    src={FlagEn}
                                    height="20" alt="en"/> English
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className={classNames("navbar-collapse collapse", {show})} id="navbar">
                        <ul className="navbar-nav mr-auto">
                            <li className="hidden">
                                <a href="#page-top"></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link page-scroll" href="/#openinghours"
                                   onClick={(e) => this.toggleCollapse()}><Trans i18nKey="DICT.openinghours" /></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link page-scroll" href="/#prices"
                                   onClick={(e) => this.toggleCollapse()}><Trans i18nKey="DICT.prices" /></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link page-scroll" href="/#contact"
                                   onClick={(e) => this.toggleCollapse()}><Trans i18nKey="DICT.contact" /></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link page-scroll" href="/#erfa"
                                   onClick={(e) => this.toggleCollapse()}><Trans i18nKey="DICT.erfa" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}
